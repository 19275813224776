/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class Values extends React.Component {
  render() {
    return (
      /* <!-- ======= Services Section ======= --> */
      <section id="values" className="services section-bg">
        <div className="container">
          <div className="section-title">
            <span>Values</span>
            <h2>Our Values</h2>
            <p></p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxs-donate-heart"></i>
                </div>
                <h4>
                  <a href="#values">Trust</a>
                </h4>
                <p>
                  Respect, integrity and candor guide our actions to do the
                  right thing.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-atom"></i>
                </div>
                <h4>
                  <a href="#values">Inclusion</a>
                </h4>
                <p>
                  We are empowered to bring our authentic selves in an open,
                  welcoming and equitable workplace.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-bookmark-heart"></i>
                </div>
                <h4>
                  <a href="#values">Care</a>
                </h4>
                <p>
                  Our people and customers inspire us to act with commitment and
                  passion.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-group"></i>
                </div>
                <h4>
                  <a href="#values">Partnership</a>
                </h4>
                <p>
                  We work collaboratively with each other and our partners to
                  win together.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-bulb"></i>
                </div>
                <h4>
                  <a href="#values">Innovation</a>
                </h4>
                <p>
                  We cultivate an open and entrepreneurial mindset in all that
                  we do.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-like"></i>
                </div>
                <h4>
                  <a href="#values">Dedication</a>
                </h4>
                <p>
                  We work with rigor, simplicity and agility to deliver
                  exceptional results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      /* <!-- End Services Section --> */
    );
  }
}

export default Values;
