import React from "react";

class Contact extends React.Component {
  render() {
    return (
      /* <!-- ======= Contact Section ======= --> */
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <span>Contact</span>
            <h2>Contact</h2>
            <p></p>
          </div>

          <div className="row">
            <div className="col d-flex align-items-stretch">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    101A, DLF Corporate Greens, <br />
                    Southern Peripheral Road, <br />
                    Sector-74A, Gurgoan-122004
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>nitesh.pandey@candorpharmaceuticals.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 9811 433 379</p>
                </div>
                <iframe
                  title="loc1"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14037.578762533363!2d76.9974593!3d28.4073451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdee55a2c01e31695!2sDLF%20Corporate%20Greens!5e0!3m2!1sen!2sca!4v1614664355536!5m2!1sen!2sca"
                  frameBorder="0"
                  style={({ border: 0 }, { width: "100 %" }, { height: 290 })}
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className="col d-flex align-items-stretch">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    Malhaur Station Road, Malhaur, <br />
                    Shop No. 2 Basement, Lucknow 226028
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@candorpharmaceuticals.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 9320 440 447</p>
                </div>

                <iframe
                  title="loc1"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d726.9904921262855!2d81.03958635802782!3d26.85581523099791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be25fd5253d59%3A0xe8dd964dff3955e4!2sMalhaur%20Railway%20Station%20Road%2C%20Gomti%20Nagar%2C%20Nijampur%20Malhaur%2C%20Uttar%20Pradesh%2C%20India!5e0!3m2!1sen!2sca!4v1637715401974!5m2!1sen!2sca"
                  frameBorder="0"
                  style={({ border: 0 }, { width: "100 %" }, { height: 290 })}
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            {/*
            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 mt-3 mt-md-0">
                    <label for="name">Your Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label for="name">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="10"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
            */}
          </div>
        </div>
      </section>
      /* <!-- End Contact Section --> */
    );
  }
}

export default Contact;
