/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TextLoop from "react-text-loop";

class CallToAction extends React.Component {
  render() {
    return (
      /* <!-- ======= Cta Section ======= --> */
      <section id="cta" className="cta">
        <div className="container">
          <div className="text-center">
            <h3>Winning in Digital Era</h3>
            <p>
              Candor Pharmaceuticals is technology-driven pharma distribution
              company focused on creating efficiency, availability, and
              accessibility in the supply chain for &nbsp;
              <TextLoop>
                <b> Pharmacies </b>
                <b> Hospitals</b>
                <b> Wholesellers</b>
              </TextLoop>{" "}
            </p>
            <a className="cta-btn" href="#contact">
              Contact Us
            </a>
          </div>
        </div>
      </section>
      /* <!-- End Cta Section --> */
    );
  }
}

export default CallToAction;
