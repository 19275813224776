/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Header from "./components/header/Header";
import HeroSection from "./components/HeroSection";
import Main from "./components/main/Main";
import Footer from "./components/Footer";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router } from "react-router-dom";

class App extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
  }

  render() {
    //const { showMainSection, showPharmaDistribution } = this.state;
    return (
      <Router>
        <div className="App">
          {/* <Header onClick={this.hideComponent.bind(this)} /> */}
          <Header />
          <HeroSection />
          {/* {showMainSection && <Main />} */}
          <Main />
          <Footer />
          <a
            href="#"
            className="back-to-top d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        </div>
      </Router>
    );
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "showMainSection":
        this.setState({
          showMainSection: !this.state.showMainSection,
        });
        break;
      case "showPharmaDistribution":
        this.setState({
          showPharmaDistribution: !this.state.showPharmaDistribution,
        });
        break;
      default:
        break;
    }
  }
}

export default App;
