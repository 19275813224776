import React from "react";
import Logo from "./Logo";
import NavigationBar from "./NavigationBar";

class Header extends React.Component {
  render() {
    //const { onClick } = this.props;
    return (
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <Logo />
          {/* <NavigationBar onClick={onClick} /> */}
          <NavigationBar />
        </div>
      </header>
    );
  }
}

export default Header;
