/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class Features extends React.Component {
  render() {
    return (
      <section id="features" class="features section-bg">
        <div class="container">
          {/* <div class="section-title">
            <h2 data-aos="fade-in">Features</h2>
            <p data-aos="fade-in">
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div> */}
          <div className="section-title">
            <span>Our Expertise</span>
            <h2>Our Expertise</h2>
            <h5 style={{ color: "gray" }}>
              <b>
                Shaping the future of healthcare through distribution and supply
                chain excellence.
              </b>
            </h5>
          </div>

          <div class="row content">
            <div class="col-md-5" data-aos="fade-right">
              <img
                src="assets/img/services/logistics.svg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-7 pt-4" data-aos="fade-left">
              <h3>Pharmaceutical and Wholesale Distribution</h3>
              <p class="fst-italic">
                Through our distribution capabilities, we ensure effective,
                reliable, and secure delivery of crucial medications to
                thousands of destinations every day. Hundreds of pharmaceutical
                manufacturers and thousands of healthcare providers rely on
                Candor Pharmaceuticals to ensure that medicines and treatments
                are available wherever and whenever they are needed. By
                seamlessly connecting our partners, we create healthier futures.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i> Giving brick and mortar pharmacies
                  a boost through technology and enabling them to compete in the
                  digital age.
                </li>
                <li>
                  <i class="bi bi-check"></i> Passing the reduced cost of the
                  value chain to our customers.
                </li>
              </ul>
            </div>
          </div>

          <div class="row content">
            <div class="col-md-5 order-1 order-md-2" data-aos="fade-left">
              <img
                src="assets/img/services/supply_chain.svg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
              <h3>Supply Chain Solutions for Hospitals</h3>
              <p class="fst-italic">
                Our supply chain solutions for hospitals are designed to ensure
                adequate inventory, reduce costs and increase operational
                efficiency.
              </p>
              <p class="fst-italic">
                Candor Pharmaceuticals remains steadfast in its commitment to
                partnering across the supply chain, with manufacturers and
                healthcare providers, to support and protect access to
                pharmaceutical care for all those in need.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i> Providing smarter schedules and
                  better predictability to help plan better, save cost, improve
                  efficiency and provide better customer satisfaction.
                </li>
                <li>
                  <i class="bi bi-check"></i> Delivering a fast and impeccable
                  omnichannel service using a new supply chain network approach.
                </li>
                <li>
                  <i class="bi bi-check"></i> Enabling easier procurement.
                </li>
              </ul>
            </div>
          </div>

          <div class="row content">
            <div class="col-md-5" data-aos="fade-right">
              <img
                src="assets/img/services/automation.svg"
                class="img-fluid"
                alt=""
                // style={{ marginTop: "20px" }}
              />
            </div>
            <div class="col-md-7 pt-5" data-aos="fade-left">
              <h3>Pharmaceutical Automation</h3>
              <p>
                Our innovative and connected Automation solutions help to
                improve processes, reduce errors and increase patient safety.
                Candor Pharmaceutical's digital solutions create a workflow
                continuum that increases efficiency, lowers costs, and saves
                time.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i> Digital solutions to break down
                  silos and achieve next-generation store efficiency.
                </li>
                <li>
                  <i class="bi bi-check"></i> Providing better service with a
                  connected inventory.
                </li>
                <li>
                  <i class="bi bi-check"></i> Enabling our customers to achieve
                  their full potential by providing workplace strategies and
                  technology innovations.
                </li>
                <li>
                  <i class="bi bi-check"></i> Access to technology to increase
                  our costomers profitibility.
                </li>
              </ul>
            </div>
          </div>

          <div class="row content">
            <div class="col-md-5 order-1 order-md-2" data-aos="fade-left">
              <img
                src="assets/img/services/hospital_setup.svg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
              <h3>Hospital Setup Services</h3>
              <p class="fst-italic">
                Candor Pharmaceutical provides hospital setup services by
                getting involved with the hospital administration at the
                planning stage and acting as part of their team to source and
                install the right equipment at minimum cost and disturbance.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i> Providing insight and expertise in
                  company decisions impacting business, management, and other
                  stakeholders.
                </li>
                <li>
                  <i class="bi bi-check"></i> Work with a wide range of
                  organizations to help in procurement.
                </li>
                <li>
                  <i class="bi bi-check"></i> Financial consultations, board
                  setup, and investment management.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      /* <!-- End Services Section --> */
    );
  }
}

export default Features;
