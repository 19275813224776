import React from "react";

class HeroSection extends React.Component {
  render() {
    return (
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Candor Pharmaceuticals</h1>
              <h2>
                Shaping the future of healthcare through distribution and supply
                chain excellence.
              </h2>
              {/* <h2>
                Candor Pharmaceuticals is technology-driven pharma distribution
                company focused on creating efficiency, availability, and
                accessibility in the supply chain for
                <TextLoop>
                  <h2 style={{ color: "MediumPurple" }}>
                    <b> Pharmacies </b>
                  </h2>
                  <h2 style={{ color: "MediumPurple" }}>
                    <b> Hospitals</b>
                  </h2>
                  <h2 style={{ color: "MediumPurple" }}>
                    <b> Wholesellers</b>
                  </h2>
                </TextLoop>{" "}
              </h2> */}
              <div className="d-flex">
                <a href="#contact" className="btn-get-started scrollto">
                  Contact Us
                </a>
                {/*
                <a
                  href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
                  className="glightbox btn-watch-video"
                >
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a>
                */}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              {/* <img
                src="assets/img/hero-img.jpg"
                className="img-fluid animated"
                alt=""
              ></img> */}
              <img
                src="assets/img/hero3.svg"
                className="img-fluid animated"
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroSection;
