/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class FeaturedServices extends React.Component {
  render() {
    return (
      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-laptop"></i>
                </div>
                <h4 className="title">
                  <a href="">Pharmaceutical and Wholesale Distribution</a>
                </h4>
                <p className="description">
                  Candor pharmaceuticals strives to ensure that medication is
                  always available where and whenever its needed while prividing
                  the most competetive pricing.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-card-checklist"></i>
                </div>
                <h4 className="title">
                  <a href="">Supply Chain Solutions for Hospitals</a>
                </h4>
                <p className="description">
                  Our supply chain solutions for hospitals is designed to ensure
                  adequate inventory, reduce costs and increase operational
                  efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-clipboard-data"></i>
                </div>
                <h4 className="title">
                  <a href="">Automation</a>
                </h4>
                <p className="description">
                  Our innovative Automation solutions help to improve processes,
                  reduce errors and increase patient safety. It creates a
                  workflow continuum that lowers costs and saves time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FeaturedServices;
