/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class Services extends React.Component {
  render() {
    return (
      /* <!-- ======= Services Section ======= --> */
      <section id="services" className="services section-bg">
        <div className="container">
          <div className="section-title">
            <span>Services</span>
            <h2>Services</h2>
            {/* <h5 style={{ color: "gray" }}>
              <b>
                Shaping the future of heathcare through distribution and supply
                chain excellence.
              </b>
            </h5> */}
          </div>

          <div className="row">
            {/*<div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Pharmaceutical and Wholesale Distribution</a>
                </h4>
                <p>
                  Candor pharmaceuticals strives to ensure that medication is
                  always available where and whenever its needed while prividing
                  the most competetive pricing.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-file"></i>
                </div>
                <h4>
                  <a href="">Supply Chain Solutions for Hospitals</a>
                </h4>
                <p>
                  Our supply chain solutions for hospitals is designed to ensure
                  adequate inventory, reduce costs and increase operational
                  efficiency.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4>
                  <a href="">Automation</a>
                </h4>
                <p>
                  Our innovative Automation solutions help to improve processes,
                  reduce errors and increase patient safety. It creates a
                  workflow continuum that lowers costs and saves time.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="">Hospital Setup Services</a>
                </h4>
                <p>
                  Candor Pharmaceutical provides small hospitals, setup services
                  by helping them in both planning and procurement.
                </p>
              </div>
            </div> */}

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="#contact">Distribution Solutions</a>
                </h4>
                <p>
                  Our distribution solutions are designed for manufacturers
                  allowing them to use Candor Pharmaceutical's extensive
                  delivery pathways to reach hospitals, pharmacies, and other
                  retailers.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="#contact">Government Procurement</a>
                </h4>
                <p>
                  Candor Pharmaceuticals is listed as an authorized seller on
                  the GeM portal. Lower cost and lesser errors are what every
                  health system wants and we are bringing that to Government
                  organizations.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="#contact">Advisors and Tools</a>
                </h4>
                <p>
                  With a flexible approach to your needs, advisers at Candor
                  Pharmaceuticals are available to clear the path with
                  resources, tools, and customized consulting services.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-arch"></i>
                </div>
                <h4>
                  <a href="#contact">Equipment</a>
                </h4>
                <p>
                  We offer a portfolio of solutions that address your specific
                  needs. From capital equipment and everyday supplies to
                  expertise and customer service, Candor Pharmaceuticals is here
                  for you.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="#contact">Medical Supplies</a>
                </h4>
                <p>
                  From personal protection equipment (PPE), hygiene, first aid
                  kits to EMS supplies, personal care items, and everything that
                  comes in between, we have got you covered.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-slideshow"></i>
                </div>
                <h4>
                  <a href="#contact">Health and Beauty Products</a>
                </h4>
                <p>
                  We also supply health and beauty retail businesses, each
                  focused on helping people look and feel their best.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      /* <!-- End Services Section --> */
    );
  }
}

export default Services;
