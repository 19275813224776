import React from "react";

class About extends React.Component {
  render() {
    return (
      /* <!-- ======= About Section ======= --> */
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <span>About Us</span>
            <h2>About Us</h2>
            {/* <h5 style={{ color: "gray" }}>
              <b>
                Shaping the future of heathcare through distribution and supply
                chain excellence.
              </b>
            </h5> */}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src="assets/img/about.png" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content">
              <h3>Who we are</h3>
              <p className="font-italic">
                We are an impact-driven organization with the misson of
                improving heathcare in every setting.
              </p>
              <p>
                At Candor Pharmaceuticals, how we do bussiness is as impartant
                as the bussiness itself. What sets us apart, is our commitment
                to work togather, unified by our values of <b>Integrity</b>,{" "}
                <b>Accountability</b>, <b>Respect</b> and <b>Excellence</b>.
              </p>
              <br />
              <h3>What we do</h3>
              <p className="font-italic">
                We work with biopharma companies, manufacturers, hospitals,
                pharmacies, governments, and others to deliver products and
                services that make healthcare more accessible and affordable.
              </p>

              <ul>
                <li>
                  <i className="bi bi-check-circle"></i> We supply industry with
                  resources, support and technology.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i> We form connections and
                  help create networks to make health care smarter and access
                  easier.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      /* <!-- End About Section --> */
    );
  }
}

export default About;
