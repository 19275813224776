import React from "react";
import FeaturedServices from "./FeaturedServices";
import About from "./About";
import Services from "./Services";
import Values from "./Values";
import Testimonials from "./Testimonials";
import Team from "./Team";
import Contact from "./Contact";
import { Route } from "react-router-dom";
import Features from "./Features";
import CallToAction from "./CallToAction";

class Main extends React.Component {
  render() {
    return (
      <main id="main">
        {/* <Route exact={true} path="/" component={FeaturedServices} /> */}

        <Route exact={true} path="/" component={Features} />
        <Route exact={true} path="/" component={Services} />
        <Route exact={true} path="/" component={CallToAction} />
        <Route exact={true} path="/" component={About} />
        <Route exact={true} path="/" component={Values} />
        {/* <Route exact={true} path="/" component={Testimonials} />
        <Route exact={true} path="/" component={Team} /> */}
        <Route exact={true} path="/" component={Contact} />
        <Route path="/pharmaDistribuion" component={Contact} />
      </main>
    );
  }
}

export default Main;
