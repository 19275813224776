import React from "react";

class Logo extends React.Component {
  render() {
    return (
      /*
      <h1 className="logo">
        <a href="index.html">eNno</a>
      </h1>
      */
      /* <!-- Uncomment below if you prefer to use an image logo --> */
      <a href="" className="logo">
        <img src="assets/img/logo.jpg" alt="" className="img-fluid" />
      </a>
    );
  }
}

export default Logo;
